import { Flex, Heading, Skeleton, Text, Balance } from "@pancakeswap/uikit";
import cakeAbi from "config/abi/cake.json";
import erc20 from "config/abi/erc20.json";
import { bscTokens, mantleTokens } from "@pancakeswap/tokens";
import { useTranslation } from "@pancakeswap/localization";
import { useIntersectionObserver } from "@pancakeswap/hooks";
import { useEffect, useState } from "react";
import { usePriceCakeBusd } from "state/farms/hooks";
import styled from "styled-components";
import {
  formatBigNumber,
  formatLocalisedCompactNumber,
} from "@pancakeswap/utils/formatBalance";
import { multicallv3 } from "utils/multicall";
import { getCakeVaultAddress } from "utils/addressHelpers";
import useSWR from "swr";
import { SLOW_INTERVAL } from "config/constants";
import cakeVaultV2Abi from "config/abi/cakeVaultV2.json";
import { BigNumber } from "@ethersproject/bignumber";

// const StyledColumn = styled(Flex)<{ noMobileBorder?: boolean; noDesktopBorder?: boolean }>`
//   flex-direction: column;
//   ${({ noMobileBorder, theme }) =>
//     noMobileBorder
//       ? `${theme.mediaQueries.md} {
//            padding: 0 16px;
//            border-left: 1px ${theme.colors.inputSecondary} solid;
//          }
//        `
//       : `border-left: 1px ${theme.colors.inputSecondary} solid;
//          padding: 0 8px;
//          ${theme.mediaQueries.sm} {
//            padding: 0 16px;
//          }
//        `}

//   ${({ noDesktopBorder, theme }) =>
//     noDesktopBorder &&
//     `${theme.mediaQueries.md} {
//            padding: 0;
//            border-left: none;
//          }
//        `}
// `

const StyledColumn = styled(Flex)<{
  noMobileBorder?: boolean;
  noDesktopBorder?: boolean;
}>`
  flex-direction: column;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 16px 8px;
  margin-top: 24px;
  grid-template-columns: repeat(2, auto);
  grid-template-areas:
    "a d"
    "b e"
    "c f";

  ${({ theme }) => theme.mediaQueries.sm} {
    grid-gap: 16px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-areas:
      "a b c"
      "d e f";
    grid-gap: 32px;
    grid-template-columns: repeat(3, auto);
  }
`;

const emissionsPerBlock = 9.9;

/**
 * User (Planet Finance) built a contract on top of our original manual CAKE pool,
 * but the contract was written in such a way that when we performed the migration from Masterchef v1 to v2, the tokens were stuck.
 * These stuck tokens are forever gone (see their medium post) and can be considered out of circulation."
 * https://planetfinanceio.medium.com/pancakeswap-works-with-planet-to-help-cake-holders-f0d253b435af
 * https://twitter.com/PancakeSwap/status/1523913527626702849
 * https://bscscan.com/tx/0xd5ffea4d9925d2f79249a4ce05efd4459ed179152ea5072a2df73cd4b9e88ba7
 */
const planetFinanceBurnedTokensWei = BigNumber.from("637407922445268000000000");
const cakeVaultAddress = getCakeVaultAddress();

const CakeDataRow = () => {
  const { t } = useTranslation();
  const { observerRef, isIntersecting } = useIntersectionObserver();
  const [loadData, setLoadData] = useState(false);
  const {
    data: { cakeSupply, burnedBalance, circulatingSupply } = {
      cakeSupply: 0,
      burnedBalance: 0,
      circulatingSupply: 0,
    },
  } = useSWR(
    loadData ? ["cakeDataRow"] : null,
    async () => {
      const totalSupplyCall = {
        abi: erc20,
        address: mantleTokens.papple.address,
        name: "totalSupply",
      };
      const burnedTokenCall = {
        abi: erc20,
        address: mantleTokens.papple.address,
        name: "balanceOf",
        params: ["0x0000000000000000000000000000000000000000"],
      };
      const cakeVaultCall = {
        abi: cakeVaultV2Abi,
        address: cakeVaultAddress,
        name: "totalLockedAmount",
      };

      const [[totalSupply], [burned]] = await multicallv3({
        calls: [totalSupplyCall, burnedTokenCall],
        allowFailure: true,
      });

      const totalBurned = planetFinanceBurnedTokensWei.add(burned);
      // const circulating = totalSupply.sub(burned.add(totalLockedAmount));
      const circulating = totalSupply.sub(burned);
      return {
        cakeSupply:
          totalSupply && burned
            ? +formatBigNumber(totalSupply)
            : 0,
        burnedBalance: burned ? +formatBigNumber(burned) : 0,
        circulatingSupply: circulating ? +formatBigNumber(circulating) : 0,
      };
    },
    {
      refreshInterval: SLOW_INTERVAL,
    }
  );
  const cakePriceBusd = usePriceCakeBusd();
  const mcap = cakePriceBusd.times(circulatingSupply);
  const mcapString = formatLocalisedCompactNumber(mcap.toNumber());

  useEffect(() => {
    if (isIntersecting) {
      setLoadData(true);
    }
  }, [isIntersecting]);

  return (
    // <Grid>
    //   <Flex flexDirection="column" style={{ gridArea: 'a' }}>
    //     <Text color="textSubtle">{t('Circulating Supply')}</Text>
    //     {circulatingSupply ? (
    //       <Balance decimals={0} lineHeight="1.1" fontSize="24px" bold value={circulatingSupply} />
    //     ) : (
    //       <Skeleton height={24} width={126} my="4px" />
    //     )}
    //   </Flex>
    //   <StyledColumn noMobileBorder style={{ gridArea: 'b' }}>
    //     <Text color="textSubtle">{t('Total supply')}</Text>
    //     {cakeSupply ? (
    //       <Balance decimals={0} lineHeight="1.1" fontSize="24px" bold value={cakeSupply} />
    //     ) : (
    //       <>
    //         <div ref={observerRef} />
    //         <Skeleton height={24} width={126} my="4px" />
    //       </>
    //     )}
    //   </StyledColumn>
    //   <StyledColumn noMobileBorder style={{ gridArea: 'c' }}>
    //     <Text color="textSubtle">{t('Max Supply')}</Text>

    //     <Balance decimals={0} lineHeight="1.1" fontSize="24px" bold value={750000000} />
    //   </StyledColumn>
    //   <StyledColumn noDesktopBorder style={{ gridArea: 'd' }}>
    //     <Text color="textSubtle">{t('Market cap')}</Text>
    //     {mcap?.gt(0) && mcapString ? (
    //       <Heading scale="lg">{t('$%marketCap%', { marketCap: mcapString })}</Heading>
    //     ) : (
    //       <Skeleton height={24} width={126} my="4px" />
    //     )}
    //   </StyledColumn>
    //   <StyledColumn style={{ gridArea: 'e' }}>
    //     <Text color="textSubtle">{t('Burned to date')}</Text>
    //     {burnedBalance ? (
    //       <Balance decimals={0} lineHeight="1.1" fontSize="24px" bold value={burnedBalance} />
    //     ) : (
    //       <Skeleton height={24} width={126} my="4px" />
    //     )}
    //   </StyledColumn>
    //   <StyledColumn style={{ gridArea: 'f' }}>
    //     <Text color="textSubtle">{t('Current emissions')}</Text>

    //     <Heading scale="lg">{t('%cakeEmissions%/block', { cakeEmissions: emissionsPerBlock })}</Heading>
    //   </StyledColumn>
    // </Grid>

    <Grid>
      <StyledColumn noMobileBorder style={{ gridArea: "a" }}>
        {cakeSupply ? (
          <Balance
            color="#400C8B"
            decimals={0}
            lineHeight="1.1"
            fontSize="24px"
            bold
            value={cakeSupply}
          />
        ) : (
          <>
            <div ref={observerRef} />
            <Skeleton height={24} width={126} my="4px" />
          </>
        )}
        <Text color="#400C8B" style={{ color: "#400C8B" }}>
          {t("Total supply")}
        </Text>
      </StyledColumn>
      <StyledColumn style={{ gridArea: "b" }}>
        {burnedBalance ? (
          <Balance
            color="#400C8B"
            decimals={0}
            lineHeight="1.1"
            fontSize="24px"
            bold
            value={burnedBalance}
          />
        ) : (
          <Skeleton height={24} width={126} my="4px" />
        )}
        <Text color="#400C8B" style={{ color: "#400C8B" }}>
          {t("Burned to date")}
        </Text>
      </StyledColumn>
      <StyledColumn style={{ gridArea: "c" }}>
        <Heading color="#400C8B" scale="lg" style={{ color: "#400C8B" }}>
          {t("%cakeEmissions%/second", { cakeEmissions: 0.0006 })}
          {/* {t("%cakeEmissions%/block", { cakeEmissions: emissionsPerBlock })} */}
        </Heading>
        <Text color="#400C8B" style={{ color: "#400C8B" }}>
          {t("Current emissions")}
        </Text>
      </StyledColumn>
    </Grid>
  );
};

export default CakeDataRow;
